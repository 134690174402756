import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"

import SEO from "@components/SEO"
import Layout from "@components/Layout"
import Content, { HTMLContent } from "@components/Content"
import { Divider, Space } from "antd"

const PublicNoticeCSS = styled.div`
  padding-top: 100px;
  padding-bottom: 50px;
  min-height: 80vh;
  max-width: 70vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .content {
    line-height: 5vh;
    font-size: 1.2rem;
    text-indent : 20px;
  }

  h1 {
    font-size: 2rem;
    font-weight: 800;
  }
  .extra {
    float: right;
    font-size: 1rem;
    lineHeight: 2rem
  }

  @media (max-width: 800px) {
    h1 {
      font-size: 1rem;
    }
    .content {
      font-size: 1rem;
    }
  }
  section {
    width: 100vw
  }

`

export const PublicNoticeTemplate = ({ title, department, dateIssue, html, contentComponent, location }) => {
  const NewsArticleContent = contentComponent || Content
  const { i18n: { language } } = useTranslation()
  return (
    <PublicNoticeCSS >
      <section>
        <h1>{title}</h1>
        <Divider />
        <NewsArticleContent content={html} className="content" />
        <div className="extra">
          <div>{department}</div>
          <div>{moment(dateIssue).format("YYYY年MM月DD日")}</div>
        </div>
      </section>
    </PublicNoticeCSS>
  )
}

const PublicNotice = ({ data: { allMarkdownRemark }, location }) => {
  const { t, i18n } = useTranslation()
  const { node: { frontmatter: { title, department, dateIssue }, html } } = allMarkdownRemark.edges[0]
  return (
    <Layout location={location} t={t} i18n={i18n}>
      <SEO title={t("title")} description={t("description")} pathname={location.pathname} />
      <PublicNoticeTemplate
        title={title}
        department={department}
        dateIssue={dateIssue}
        html={html}
        contentComponent={HTMLContent}
        location={location}
      />
    </Layout>
  )
}

PublicNotice.propTypes = {
  location: PropTypes.object.isRequired
}

export default PublicNotice

export const query = graphql`
    query ($language: String!, $id: String!) {
        locales: allLocale(filter: {ns: {in: ["public-notice","footer"]}, language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        allMarkdownRemark (filter: { id: { eq: $id }}) {
          edges {
              node {
                  id
                  html
                  frontmatter {
                      dateIssue
                      title
                      description
                      department
                  }
                  fields {
                      langKey
                      slug
                  }

              }
          }
      }
    }
`;
